body {
  font-family: Montserrat, "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.calendar{
  background: white;
  width: 90vw;
}

.calendar .calendar-header {
  background-color: #4176B5;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
  border: 0;
  color: white;
}

.calendar .calendar-header .year-title {
  font-size: 20;
}

.calendar .calendar-header .year-title:hover,
.calendar .calendar-header .prev:hover,
.calendar .calendar-header .next:hover{
  background: rgba(255, 255, 255, 0.2);
}

.calendar .calendar-header .year-title:not(.year-neighbor):not(.year-neighbor2) {
  border-bottom: 2px solid #ccc;
}

.calendar .calendar-header .year-neighbor {
  color: inherit;
  opacity: 0.7;
}

.calendar .calendar-header .year-neighbor2 {
  color: inherit;
  opacity: 0.4;
}

.calendar .months-container .month-container {
  margin-bottom: 2vh;
  height: 36vh !important;
}

.calendar table.month {
  background-color: white;
  box-shadow: 0 3px 6px 0 rgba(131, 126, 126, 0.5);
  border-radius: 5px 5px 1px 1px;
  height: 95% !important;
  width: 95% !important; 
}

.calendar table.month th.month-title {
  /* background-color: rgba(15, 36, 45, 0.45); */
  background-color: rgb(65, 118, 181, .9);
  color: white;
  padding: 6px;
  font-size: 14;
  font-weight: 550;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.calendar table.month th.day-header {
  padding-top: 8px;
  padding-bottom: 5px;
  color: #666;
  font-weight: 500;
}

.calendar table.month td.day .day-content {
  color: #666;
  padding-top: 0.5rem !important;
  padding-bottom: 0.6rem !important;
  border-radius: 2px 2px 0 0 !important;
}








/* body {
    font-family: "Roboto","Helvetica","Arial",sans-serif;
  }
  
  .calendar .calendar-header {
    background-color: white;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
    border: 0;
  }
  
  .calendar .calendar-header .year-title {
    font-size: 1.3rem;
  }
  
  .calendar .calendar-header .year-title:hover,
  .calendar .calendar-header .prev:hover,
  .calendar .calendar-header .next:hover{
    background: rgba(255, 255, 255, 0.2);
  }
  
  .calendar .calendar-header .year-title:not(.year-neighbor):not(.year-neighbor2) {
    border-bottom: 2px solid #ccc;
  }
  
  .calendar .calendar-header .year-neighbor {
    color: inherit;
    opacity: 0.7;
  }
  
  .calendar .calendar-header .year-neighbor2 {
    color: inherit;
    opacity: 0.4;
  }
  
  .calendar .months-container .month-container {
    min-height: 20rem;
    min-width: 18rem;
    margin-bottom: 4rem;
  }
  
  .calendar table.month {
    background-color: white;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
    min-height: 20vh;
    min-width: 5vw;
    border-radius: 5px;
  }
  

  .calendar table.month th.month-title {
    background-color: rgba(25, 118, 210, 0.8);
    color: white;
    padding: 6px;
    font-size: 1rem;
    font-weight: 600;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    height: 3vh;
  }
  
  .calendar table.month th.day-header {
    padding-top: 8px;
    padding-bottom: 5px;
    color: #666;
    font-weight: 500;
  }
  
  .calendar table.month td.day .day-content {
    color: #666;
    font-size: 0.9rem;
    padding: 1rem 0.5rem !important;
    border-radius: 2px 2px 0 0 !important;
  }
 */
